<template>
	<div>
		<App />
	</div>
</template>
<script>
import App from '../../application/App.vue';

export default {
	components: {
		App,
	},
};
</script>
