import { setupApp } from 'src/application/main';

import StandaloneApp from './StandaloneApp.vue';

import { getRouter } from '../../router';
import { createWebHistory } from 'vue-router';

const router = getRouter({
	history: createWebHistory(),
});

const app = setupApp({ root: StandaloneApp, router });

app.mount('#app');

export default {};
